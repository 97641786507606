<template>
  <div class="position-relative">
    <location-component
      :place="place.place && place.place.geometry.location || {}"
      :station="station || {}"
      class="wet-location-map wet-full-height"
      ref="location"
      :draggableLocMarker="draggableLocMarker"
      @marker-dragend="markerDragend"
      @map-click="onMapClick"
    ></location-component>
    <div class="wet-section w-100" style="z-index:10;position:absolute;top:0;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-lg-6">
            <div class="form-grouped d-flex">
              <a
                class="wet-button"
                id="location-get-user-position"
                href="#"
                @click.prevent="getLocation"
              >
                <i v-html="iconSearch"></i>
              </a>
              <places-component
                :searchCountries="searchCountries"
                :fulladdress="true"
                :show-label="false"
                class="w-100"
                :invalid.sync="invalid"
                :id="'wet-input-place'"
                ref="placesComponent"
                :placeholder="locationInputPlaceholder"
                @change-address="updateAddress"
                @change-invalid-value="updateInvalidValue"
                @change-search-error="updateSearchErrorValue"
              />
            </div>
            <transition name="fade">
              <div
                v-if="invalid || searchError"
                class="wet-location-notice text-primary"
              >
                {{str.location.place_input_error}}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <wet-modal
      id="wet-price-error-modal"
      icon="map"
      :opened="modalsOpened.confirmation"
      @close="$set(modalsOpened, 'confirmation', false)"
      :title="str.location.confirmation_title"
      :content="confirmationMessage"
      :actions="[
        {
          title: str.commonApp.cancel,
          name: 'location-confirmation-cancel',
          event: () => $set(modalsOpened, 'confirmation', false)
        },
        {
          title: str.commonApp.ok,
          name: 'location-confirmation-ok',
          event: () => {
            $set(modalsOpened, 'confirmation', false);
            submit();
          }
        }
      ]"
    ></wet-modal>
  </div>
</template>
<script>
import places from '@/mixins/places';
import common from '@/mixins/common';
import placesComponent from '@/components/placesComponent.vue';
import locationComponent from '@/components/locationComponent.vue';
import LogService from '@/services/LogService';
import WpSection from '@/enums/wpSection';

export default {
  name: 'ViewLocation',
  mixins: [common, places],
  components: {
    placesComponent,
    locationComponent,
  },
  data() {
    return {
      place: {},
      searchCountries: ['DE'],
      searchString: '',
      station: {},
      progress: [1, 5],
      str: {
        location: {
          place_input_error: '',
          confirmation_title: '',
          confirmation_message_template: '',
          place_input_placeholder: '',
          address_message_title: '',
          error_address_not_valid: '',
          address_message: '',
        },
        commonApp: {
          error_address_not_valid: '',
          ok: '',
          cancel: '',
        },
      },
      findStaApi: '/api/station/find',
      sourceTypesApi: '/api/product/source_types',
      iconSearch: `
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
      </svg>
      `,
      invalid: false,
      searchError: false,
      markerLocation: null,
      markerStation: null,
      hasStation: false,
      modalsOpened: {
        confirmation: false,
      },
      userStatus: null,
      prevPage: null,
      draggableLocMarker: false,
      inputType: '',
    };
  },
  computed: {
    orderBundle() {
      return process.env.VUE_APP_DROUGHT_PAGE_VERSION === 'order-bundle';
    },
    message() {
      return `\
<div><strong>${this.str.location.address_message_title}</strong></div>
<div>${this.str.location.address_message}</div>`;
    },
    confirmationMessage() {
      const template = this.str.location.confirmation_message_template;
      const location = this.place.place?.formatted_address;
      const station = this.station?.name;
      const id = this.station?.id;
      const coords = `${this.station?.latitude}, ${this.station?.longitude}`;
      const { markerLocation, markerStation } = this.$refs.location || {};
      let len = 0;
      if (global.window?.google
        && this.isMarker(markerLocation) && this.isMarker(markerStation)) {
        len = this.getDistance(markerLocation, markerStation);
      }

      return this.format(template, location, station, id, coords, len, station);
    },
    locationInputPlaceholder() {
      return this.str.location.place_input_placeholder;
    },
  },
  watch: {
    place: {
      async handler(val) {
        // go to place on map
        if (val.place?.geometry?.location) {
          // search station
          this.getStation();
        }
      },
    },
    invalid: {
      immediate: true,
      handler(val) {
        this.updNextDisabledState(val);
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const vue = vm;
      vue.prevPage = from.name;
    });
  },
  created() {
    this.init();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);

    // clear bottomBarPrependMessage
    this.$store.commit('SET_BOTTOMBAR_PREPEND_MESSAGE', null);
  },
  methods: {
    next() {
      // next button click handler
      this.$refs.placesComponent.$v.$touch();
      LogService.log(this.invalid);
      if (this.$refs.placesComponent.$v.$error) {
        return;
      }
      this.$set(this.modalsOpened, 'confirmation', true);
    },
    back() {
      // back button click handler
      if (this.prevPage) {
        this.$router.back();
      } else {
        // set initial page state
        this.$router.go();
      }
    },
    updateSearchErrorValue(searchErrorValue) {
      this.searchError = searchErrorValue;
    },
    updateInvalidValue(invalidValue) {
      LogService.log('Location component, update invalid value', invalidValue);
      this.invalid = invalidValue;
    },
    updateAddress(address) {
      LogService.log('updateAddress, invalid', this.invalid);
      this.place = address;
      if (this.invalid) {
        this.showErrorPopup();
      } else {
        this.inputType = 'text';
      }
    },
    showErrorPopup() {
      LogService.log('Location show error popup');
      const message = this.format(
        this.str.commonApp.error_address_not_valid,
        this.place.address,
      );
      this.showPopup(message);
    },
    getLocation() {
      LogService.log('navigator in global:', ('navigator' in global));
      if ('navigator' in global) {
        global.navigator.geolocation.getCurrentPosition((e) => {
          this.$set(this.googleApis, 'LatLng', { lat: Number.parseFloat(e.coords.latitude), lng: Number.parseFloat(e.coords.longitude) });
          // this.setPos(this.googleApis.LatLng);
          this.getAddress('', this.googleApis.LatLng)
            .then((loc) => {
              if (loc[0]) {
                const address = { ...this.gPlaceObjToAddr(loc[0]) };
                LogService.log('Location, getLocation, set places search', address);
                this.$refs.placesComponent.search = address.address;
                if (!this.invalid) {
                  this.place = address;
                  this.inputType = 'tap';
                }
              }
              LogService.log('Google locations:', loc);
            });
          LogService.log('get user coordinates', {
            lat: Number.parseFloat(e.coords.latitude),
            lng: Number.parseFloat(e.coords.longitude),
          });
        },
        (e) => {
          if (this.$isDev) LogService.log(e);
        });
      }
    },
    async getStation() {
      // call API station
      if (!this.place.street || !this.place.house) {
        this.station = {};
        return;
      }

      this.hasStation = false;
      // call api
      const data = {
        location: {
          latitude: this.place.place?.geometry?.location?.lat(),
          longitude: this.place.place?.geometry?.location?.lng(),
          address: this.place.place?.formatted_address,
          city: this.place.city,
          country: this.place.country,
          country_code: this.place.country_code,
          zip: this.place.zip,
          street: this.place.street,
          house: this.place.house,
          place_id: this.place.id,
          input_type: this.inputType,
        },
      };
      let res = null;
      try {
        res = await this.$webAppAPI.postFindStation(data);
        this.station = res.data.station;
        this.userStatus = res.data.user.status;
      } catch (error) {
        this.station = {};
      }
      if (this.$isDev) LogService.log('station', this.station);
      if ('id' in this.station) {
        this.hasStation = true;
      }
    },
    submit() {
      let defaultRoute;

      if (this.orderBundle) {
        defaultRoute = 'done-bundle';
      } else {
        defaultRoute = ['0', '1', '3'].includes(this.userStatus?.toString())
          ? 'done' : 'letus';
      }

      const name = this.$route.query?.redirect || defaultRoute;
      this.$router.push({ name, params: { ...this.$route.params } });
    },
    updNextDisabledState(val) {
      this.$emit('update:nextDisabled', val);

      // show bottomBarPrependMessage
      this.$store.commit('SET_BOTTOMBAR_PREPEND_MESSAGE', !val ? this.message : null);
    },
    async init() {
      await Promise.all([
        this.$store.commit('SET_APP_LAYOUT', { ...this.appLayout, progress: this.progress }),
        this.$root.$on('buttonNext', this.next),
        this.$root.$on('buttonBack', this.back),
        this.getWordPressStrings([WpSection.LOCATION, WpSection.COMMON_APP], WpSection.LOCATION),
        this.callSourceTypesApi(),
      ]);
    },
    async callSourceTypesApi() {
      const url = this.sourceTypesApi;
      const res = await this.callApi({ url });
      const searchCountries = res.data?.insurance_country;
      if (searchCountries) this.searchCountries = searchCountries;
    },
    async markerDragend(e) {
      LogService.log('Location markerDragend');
      const loc = await this.getAddress('', e.latLng);
      const address = { ...this.gPlaceObjToAddr(loc[0]) };
      LogService.log('markerDragend address', address);
      this.$refs.placesComponent.search = address.address;
      if (!this.invalid) {
        this.place = address;
        this.inputType = 'tap';
      }
    },
    onLocationChange(address) {
      this.invalid = !address.street || !address.house;
      if (this.invalid) {
        const message = this.format(
          this.str.commonApp.error_address_not_valid,
          address.address,
        );
        this.showPopup(message);
      }
    },
    showPopup(message) {
      const modal = {
        id: 'wet-invalid-location-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-invalid-location-modal-ok',
            title: this.str.commonApp.ok,
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    onMapClick() {},
  },
};
</script>
<style lang="scss">
  .wet-location-map {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
  }
  .wet-location-notice {
    border-radius: var(--border-radius);
    padding: .5rem 1.5rem;
    background-color: white;
  }
</style>
