var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('location-component',{ref:"location",staticClass:"wet-location-map wet-full-height",attrs:{"place":_vm.place.place && _vm.place.place.geometry.location || {},"station":_vm.station || {},"draggableLocMarker":_vm.draggableLocMarker},on:{"marker-dragend":_vm.markerDragend,"map-click":_vm.onMapClick}}),_c('div',{staticClass:"wet-section w-100",staticStyle:{"z-index":"10","position":"absolute","top":"0"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-sm-8 col-lg-6"},[_c('div',{staticClass:"form-grouped d-flex"},[_c('a',{staticClass:"wet-button",attrs:{"id":"location-get-user-position","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getLocation.apply(null, arguments)}}},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.iconSearch)}})]),_c('places-component',{ref:"placesComponent",staticClass:"w-100",attrs:{"searchCountries":_vm.searchCountries,"fulladdress":true,"show-label":false,"invalid":_vm.invalid,"id":'wet-input-place',"placeholder":_vm.locationInputPlaceholder},on:{"update:invalid":function($event){_vm.invalid=$event},"change-address":_vm.updateAddress,"change-invalid-value":_vm.updateInvalidValue,"change-search-error":_vm.updateSearchErrorValue}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.invalid || _vm.searchError)?_c('div',{staticClass:"wet-location-notice text-primary"},[_vm._v(" "+_vm._s(_vm.str.location.place_input_error)+" ")]):_vm._e()])],1)])])]),_c('wet-modal',{attrs:{"id":"wet-price-error-modal","icon":"map","opened":_vm.modalsOpened.confirmation,"title":_vm.str.location.confirmation_title,"content":_vm.confirmationMessage,"actions":[
      {
        title: _vm.str.commonApp.cancel,
        name: 'location-confirmation-cancel',
        event: () => _vm.$set(_vm.modalsOpened, 'confirmation', false)
      },
      {
        title: _vm.str.commonApp.ok,
        name: 'location-confirmation-ok',
        event: () => {
          _vm.$set(_vm.modalsOpened, 'confirmation', false);
          _vm.submit();
        }
      }
    ]},on:{"close":function($event){return _vm.$set(_vm.modalsOpened, 'confirmation', false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }