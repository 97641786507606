<template>
  <div ref="map" id="map"></div>
</template>
<script>
import grayscale from '@/map-styles/grayscale.json';
import places from '@/mixins/places';
import { mapState } from 'vuex';

export default {
  mixins: [places],
  name: 'locationComponent',
  props: {
    station: {
      type: Object,
      default: null,
    },
    place: {
      type: Object,
      default: null,
    },
    draggableLocMarker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markerLocation: null,
      markerStation: null,
      line: null,
      hasStation: false,
    };
  },
  computed: {
    ...mapState(['colors']),
    placeReady() {
      return this.googleApis.map && this.place;
    },
    stationReady() {
      return this.googleApis.map && this.station;
    },
  },
  watch: {
    placeReady: {
      immediate: true,
      handler(val) {
        if (!val) return;

        const draggable = this.draggableLocMarker;
        this.markerLocation = this.placeMarker(this.markerLocation, val, this.iconMarker('primary'), draggable);
        this.drawLine();
      },
    },
    stationReady: {
      handler(val) {
        if (!val) return;

        this.markerStation = this.placeMarker(this.markerStation, val, this.iconMarker('secondary', 'DWD'));
        this.drawLine();
      },
    },
  },
  mounted() {
    this.loadMapScript()
      .then(() => {
        const el = this.$refs.map;
        this.initMap(el, grayscale);
      });
  },
  methods: {
    validPlace(place) {
      let lat;
      let lng;
      if (typeof place.lat === 'function') {
        lat = place.lat();
        lng = place.lng();
      }
      lat = Number.parseFloat((lat || place.latitude), 10);
      lng = Number.parseFloat((lng || place.longitude), 10);
      return { lat, lng, valid: lat && lng };
    },
    drawLine() {
      // del old line
      if (this.line) {
        this.delLine(this.line);
        this.line = null;
      }
      if (!this.markerLocation || !this.markerStation || !this.googleApis.map) return;

      // set position
      this.zoom([this.markerLocation, this.markerStation]);

      // set line
      this.line = this.setLine({
        start: this.markerLocation,
        end: this.markerStation,
        color: this.colors.secondary,
      });
    },
    placeMarker(marker, val, svg, draggable) {
      /** validate and update or place marker on  map */
      let newMarker = marker;
      if (this.validPlace(val).valid) {
        // set marker
        if (!this.isMarker(newMarker)) {
          newMarker = this.setMarker(
            this.validPlace(val),
            { svg, draggable },
          );
        } else {
          this.setMarkerPosition(newMarker, this.validPlace(val));
        }

        this.setPos(this.validPlace(val));
        this.setZoom(12);
      } else if (this.isMarker(newMarker)) {
        this.removeMarker(newMarker);
        newMarker = null;
        // set position
        this.zoom([this.markerLocation]);
      }
      return newMarker;
    },
    iconMarker(color, text) {
      const style = `x:50%;y:50%;dominant-baseline:middle;text-anchor:middle;font-size:.5rem;\
fill:%23${this.colors[color || 'primary'].slice(1)}!important;font-weight:bold;`;
      const inner = text
        ? `<text style="${style}" x="14" y="14">${text}</text>`
        : `<circle cx="14" cy="14" r="8" style="fill:%23${this.colors[color || 'primary'].slice(1)};"/>`;
      const icon = `\
<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
  <circle cx="14" cy="14" r="12" fill="%23ffffff"/>
  ${inner}
  <circle cx="14" cy="14" r="12" fill="transparent" stroke-width="2" style="stroke:%23${this.colors[color || 'primary'].slice(1)};"/>
</svg>`;
      return icon;
    },
  },
};
</script>
